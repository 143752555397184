export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "us-east-1",
    BUCKET: "spa-dev-rawemails-188005094284"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://notesapi.elp.li"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_W5sZpnjqk",
    APP_CLIENT_ID: "ktllhujlfe5oak9tpcipcrhp9",
    IDENTITY_POOL_ID: "us-east-1:8d20acde-4407-4f1f-97b2-e13bd3d7fdf3"
  }
};
